import { lazy } from "react";
import { Navigate } from "react-router-dom";

import AuthGuard from "./auth/AuthGuard";
import { authRoles } from "./auth/authRoles";

import Loadable from "./components/Loadable";
import MatxLayout from "./components/MatxLayout/MatxLayout";

import materialRoutes from "app/views/material-kit/MaterialRoutes";

// SESSION PAGES
const NotFound = Loadable(lazy(() => import("app/views/sessions/NotFound")));
const Error500 = Loadable(lazy(() => import("app/views/sessions/Error500")));
const LoginPage = Loadable(lazy(() => import("app/views/sessions/LoginPage")));
const JwtLogin = Loadable(lazy(() => import("app/views/sessions/JwtLogin")));
const JwtRegister = Loadable(lazy(() => import("app/views/sessions/JwtRegister")));
const ForgotPassword = Loadable(lazy(() => import("app/views/sessions/ForgotPassword")));
// E-CHART PAGE
const AppEchart = Loadable(lazy(() => import("app/views/charts/echarts/AppEchart")));
// DASHBOARD PAGE
const Analytics = Loadable(lazy(() => import("app/views/dashboard/Analytics")));
// Categories
const Categories = Loadable(lazy(() => import("app/views/categories/list")));
const AddCategory = Loadable(lazy(() => import("app/views/categories/create")));
const EditCategory = Loadable(lazy(() => import("app/views/categories/edit")));
//Vendors
const Vendors = Loadable(lazy(() => import("app/views/vendors/list")));
const AddVendor = Loadable(lazy(() => import("app/views/vendors/create")));
const EditVendor = Loadable(lazy(() => import("app/views/vendors/edit")));
const Profile = Loadable(lazy(() => import("app/views/vendors/profile")));
//Orders
const Orders = Loadable(lazy(() => import("app/views/orders/orders")));
const OrderDetails = Loadable(lazy(() => import("app/views/orders/orderDetails")));
//Products
const Products = Loadable(lazy(() => import("app/views/products/products")));
const AddProducts = Loadable(lazy(() => import("app/views/products/create")));
const EditProducts = Loadable(lazy(() => import("app/views/products/edit")));
const ViewProducts = Loadable(lazy(() => import("app/views/products/details")));
//ads
const Ads = Loadable(lazy(() => import("app/views/adsrequest/ads")));
//admins
const Admins = Loadable(lazy(() => import("app/views/admins/list")));
//ads config
const Configuration = Loadable(lazy(() => import("app/views/adverts/configuration")));
const AddConfig = Loadable(lazy(() => import("app/views/adverts/configads")));
const EditConfig = Loadable(lazy(() => import("app/views/adverts/editConfig")));
//Accounting
const Accounting = Loadable(lazy(() => import("app/views/accounts/accounting")));
const Transactions = Loadable(lazy(() => import("app/views/accounts/transactions")));
//Queries
const Queries = Loadable(lazy(() => import("app/views/queries/list")));
//users
const Users = Loadable(lazy(() => import("app/views/users/list")));
const CustomerProfile = Loadable(lazy(() => import("app/views/users/profile")));
//Coupons
const Coupons = Loadable(lazy(() => import("app/views/coupons/list")));
const AddCoupon = Loadable(lazy(() => import("app/views/coupons/create")));
const EditCoupon = Loadable(lazy(() => import("app/views/coupons/edit")));
//Attributes
const Attributes = Loadable(lazy(() => import("app/views/attributes/list")));
const AddAttribute = Loadable(lazy(() => import("app/views/attributes/create")));
const EditAttribute = Loadable(lazy(() => import("app/views/attributes/edit")));
//banners
const Banners = Loadable(lazy(() => import("app/views/banners/list")));
const AddBanner = Loadable(lazy(() => import("app/views/banners/create")));
const EditBanner = Loadable(lazy(() => import("app/views/banners/edit")));

//faqs
const Faqs = Loadable(lazy(() => import("app/views/faqs/list")));
const AddFaq = Loadable(lazy(() => import("app/views/faqs/create")));
const EditFaq = Loadable(lazy(() => import("app/views/faqs/edit")));

//notifications
const Notifications = Loadable(lazy(() => import("app/views/notifications/list")));
const AddNotification = Loadable(lazy(() => import("app/views/notifications/create")));

//configuration
const Setting = Loadable(lazy(() => import("app/views/configuration/setting")));
const Update = Loadable(lazy(() => import("app/views/configuration/update")));

//Terms And Conditions
const TermsAndConditions = Loadable(lazy(() => import("app/views/terms&condition/view")));
const TermsAndConditionsUpdate = Loadable(lazy(() => import("app/views/terms&condition/update")));

//Privacy Policy
const PrivacyPolicy = Loadable(lazy(() => import("app/views/privacypolicy/privacypolicy")));
const PrivacyPolicyUpdate = Loadable(lazy(() => import("app/views/privacypolicy/update")));

//Privacy Policy URL
const PrivacyPolicyPage = Loadable(lazy(() => import("app/views/privacy-policy/PrivacyPolicy")));

const routes = [
  {
    element: (
      <AuthGuard>
        <MatxLayout />
      </AuthGuard>
    ),
    children: [
      // dashboard route
      { path: "/dashboard/analytics", element: <Analytics />, auth: authRoles.admin },
      ,
      //categories
      { path: "/dashboard/categories", element: <Categories />, auth: authRoles.admin },
      {
        path: "/dashboard/categories/create",
        element: <AddCategory />,
        auth: authRoles.admin
      },
      { path: "/dashboard/categories/:id/edit", element: <EditCategory />, auth: authRoles.admin },
      //vendors
      { path: "/dashboard/vendors", element: <Vendors />, auth: authRoles.admin },
      { path: "/dashboard/vendors/create", element: <AddVendor />, auth: authRoles.admin },
      { path: "/dashboard/vendors/:id/edit", element: <EditVendor />, auth: authRoles.admin },
      { path: "/dashboard/vendors/:id/profile", element: <Profile />, auth: authRoles.admin },

      //orders
      { path: "/dashboard/orders", element: <Orders />, auth: authRoles.admin },
      { path: "/dashboard/orders/:id/details", element: <OrderDetails />, auth: authRoles.admin },

      //products
      { path: "/dashboard/products", element: <Products />, auth: authRoles.admin },
      { path: "/dashboard/products/create", element: <AddProducts />, auth: authRoles.admin },
      { path: "/dashboard/products/:id/edit", element: <EditProducts />, auth: authRoles.admin },
      { path: "/dashboard/products/:id/details", element: <ViewProducts />, auth: authRoles.admin },

      //ads
      { path: "/dashboard/Ads", element: <Ads />, auth: authRoles.admin },

      //admins

      { path: "/dashboard/admins", element: <Admins />, auth: authRoles.admin },
      //ads config
      { path: "/dashboard/Ad-Config", element: <Configuration />, auth: authRoles.admin },
      { path: "/dashboard/Ad-Config/create", element: <AddConfig />, auth: authRoles.admin },
      { path: "/dashboard/Ad-Config/:id/edit", element: <EditConfig />, auth: authRoles.admin },

      //account management
      { path: "/dashboard/accounts", element: <Accounting />, auth: authRoles.sa },
      {
        path: "/dashboard/accounts/:id/transactions",
        element: <Transactions />,
        auth: authRoles.sa
      },

      //queries
      { path: "/dashboard/queries", element: <Queries />, auth: authRoles.admin },

      //users
      { path: "/dashboard/customers", element: <Users />, auth: authRoles.admin },
      {
        path: "/dashboard/customers/:id/profile",
        element: <CustomerProfile />,
        auth: authRoles.admin
      },

      //coupons
      { path: "/dashboard/coupons", element: <Coupons />, auth: authRoles.admin },
      { path: "/dashboard/coupons/create", element: <AddCoupon />, auth: authRoles.admin },
      { path: "/dashboard/coupons/:id/edit", element: <EditCoupon />, auth: authRoles.admin },
      //attributes
      { path: "/dashboard/attributes", element: <Attributes />, auth: authRoles.admin },
      {
        path: "/dashboard/attributes/create",
        element: <AddAttribute />,
        auth: authRoles.admin
      },
      { path: "/dashboard/attributes/:id/edit", element: <EditAttribute />, auth: authRoles.admin },
      //Banners
      { path: "/dashboard/banners", element: <Banners />, auth: authRoles.admin },
      { path: "/dashboard/banners/create", element: <AddBanner />, auth: authRoles.admin },
      { path: "/dashboard/banners/:id/edit", element: <EditBanner />, auth: authRoles.admin },
      //faqs
      { path: "/dashboard/faqs", element: <Faqs />, auth: authRoles.admin },
      { path: "/dashboard/faqs/create", element: <AddFaq />, auth: authRoles.admin },
      { path: "/dashboard/faqs/:id/edit", element: <EditFaq />, auth: authRoles.admin },
      //Notifications
      { path: "/dashboard/notifications", element: <Notifications />, auth: authRoles.admin },
      {
        path: "/dashboard/notifications/create",
        element: <AddNotification />,
        auth: authRoles.admin
      },
      //Configuration
      { path: "/dashboard/configuration/setting", element: <Setting />, auth: authRoles.admin },
      {
        path: "/dashboard/configuration/setting/update",
        element: <Update />,
        auth: authRoles.admin
      },
      //Terms & Conditions
      {
        path: "/dashboard/terms-and-condition/view",
        element: <TermsAndConditions />,
        auth: authRoles.admin
      },
      {
        path: "/dashboard/terms-and-condition/update",
        element: <TermsAndConditionsUpdate />,
        auth: authRoles.admin
      },
      //Privacy Policy
      {
        path: "/dashboard/privacy-policy/view",
        element: <PrivacyPolicy />,
        auth: authRoles.admin
      },
      {
        path: "/dashboard/privacy-policy/update",
        element: <PrivacyPolicyUpdate />,
        auth: authRoles.admin
      }
    ]
  },

  // session pages route,
  { path: "/session/signin", element: <JwtLogin /> },
  { path: "/session/login", element: <LoginPage /> },
  { path: "/session/404", element: <NotFound /> },
  { path: "/session/signup", element: <JwtRegister /> },
  { path: "/session/forgot-password", element: <ForgotPassword /> },

  { path: "/", element: <Navigate to="dashboard/analytics" /> },
  { path: "/privacy-policy", element: <PrivacyPolicyPage /> },
  { path: "*", element: <NotFound /> },
  { path: "/500", element: <Error500 /> }
];

export default routes;
