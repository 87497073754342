import axios from "axios";
import { toast } from "react-toastify";

function getAccessToken() {
  return localStorage.getItem("accessToken");
}

export const checkLoginError = (err, routr) => {
  if (err.response?.status === 401) {
    toast.error("Your token has expired, Kindly login again!");
    localStorage.clear();
    setTimeout(() => {
      routr ? routr.push("/session/login") : window.location.navigate("/session/login");
    }, 500);
    return true;
  } else if (err.message === "Request timed out") {
    routr.push("/500");
  } else {
    toast.error(
      typeof err?.response?.data?.message === "string"
        ? err?.response?.data?.message
        : "Something went wrong on our side"
    );
  }
};

const headersWithToken = () => {
  return {
    headers: {
      "x-lang": "en",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAccessToken()}`
    }
  };
};

const headersWithFileToken = () => {
  return {
    headers: {
      "x-lang": "en",
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${getAccessToken()}`
    }
  };
};

export const getRequest = async (endpoint, router) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
    const timeout = 10000; // 10 seconds

    const timeoutPromise = new Promise((_, reject) => {
      setTimeout(() => reject(new Error("Request timed out")), timeout);
    });
    const res = await Promise.race([axios.get(url, headersWithToken()), timeoutPromise]);
    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
  } catch (error) {
    checkLoginError(error, router);
    console.log(error);
  }
};

export const UploadFiles = async (file) => {
  try {
    let form = new FormData();
    form.append("file", file);
    let url = `${process.env.REACT_APP_BASE_URL}/upload/`;
    const res = await axios.post(url, form, headersWithFileToken());
    if (res?.data?.statusCode === 200) {
      return res?.data?.data?.url;
    } else {
      return false;
    }
  } catch (error) {
    checkLoginError(error);
    console.log(error);
    return false;
  }
};

export const UploadMultiple = async (files) => {
  const uploadedFiles = [...files]; // Create a copy of the files array

  for (let i = 0; i < uploadedFiles.length; i++) {
    const file = uploadedFiles[i];

    // Check if the item is an object (a file) or already a string (URL)
    if (typeof file === "object" && file !== null) {
      try {
        // Upload the file and replace the object with the returned URL
        const url = await UploadFiles(file);
        uploadedFiles[i] = url; // Replace the object with the URL
      } catch (error) {
        console.error(`Failed to upload ${file.path}`, error);
      }
    } else {
      console.log(`File ${file} is already uploaded. Skipping...`);
    }
  }

  return uploadedFiles; // Return the updated array with URLs only
};

export const postRequest = async (endpoint, data, router) => {
  try {
    let url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
    const res = await axios.post(url, data, headersWithToken());
    if (res?.data?.statusCode === 200) {
      return res?.data;
    } else {
      return res;
    }
  } catch (error) {
    console.log(error);
    checkLoginError(error, router);
  }
};

export const putRequest = async (endpoint, data, router) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
    const res = await axios.put(url, data, headersWithToken());
    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
  } catch (error) {
    checkLoginError(error, router);
  }
};

export const patchRequest = async (endpoint, data, router) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
    const res = await axios.patch(url, data, headersWithToken());
    if (res?.data?.statusCode === 200) {
      return res?.data;
    }
  } catch (error) {
    checkLoginError(error, router);
  }
};

export const deleteRequest = async (endpoint, data, router) => {
  try {
    const url = `${process.env.REACT_APP_BASE_URL}${endpoint}`;
    const res = await axios.delete(url, {
      headers: {
        "x-lang": "en",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAccessToken()}`
      },
      data: data
    });
    if (res?.data?.statusCode === 200) {
      return res?.data;
    } else {
      // console.log(res);
      return false;
    }
  } catch (error) {
    checkLoginError(error, router);
  }
};
